import React from 'react';

import Layout from 'components/Layout';

export const PageLoading = ({ withFooter = false }) => (
  <Layout
    navbar={null}
    footer={null}
    mobileNav={null}
    mobileFooter={withFooter ? undefined : null}
    mobileReturnTo={false}
  >
    <section className="section">
      <br />
      <br />
      <div className="container">
        <div className="placeholder" style={{ maxWidth: 200, height: 50 }} />
        <div className="placeholder" style={{ height: 120 }} />
        <div className="placeholder" style={{ height: 120 }} />
      </div>
      <style jsx>{`
        @import 'styles/variables';

        @keyframes placeholderAnimate {
          0% {
            background-position: -650px 0;
          }
          100% {
            background-position: 650px 0;
          }
        }

        .placeholder {
          margin-bottom: 2rem;
          // Fallback
          background: #f6f7f8;
          // Animation
          animation-duration: 1.7s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-name: placeholderAnimate;
          background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
          background-size: 1300px; // Animation Area
        }
      `}</style>
    </section>
  </Layout>
);
