import React from 'react';
import Head from 'next/head';

import { NOT_FOUND } from 'lib/utils/errors';
import { MOBILE_APP } from 'lib/env';
import { PageLoading } from 'lib/mobile/PageLoading';

import ErrorPage from './_error';

const Error404Page = () => {
  // TODO-mobileapp: horrible HACK to open 404 urls externally
  // useEffect(() => {
  //   if (MOBILE_APP) {
  //     const url = absUrl(Router.asPath);
  //     Browser.open({
  //       url,
  //     }).then(() => {
  //       setTimeout(() => {
  //         Router.back();
  //       }, 3000);
  //     });
  //   }
  // }, []);

  if (MOBILE_APP) return <PageLoading />;

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>

      <ErrorPage code={NOT_FOUND} />
    </>
  );
};

export default Error404Page;
